<template>
  <div>
    <div class="shadow-2"><q-toolbar class="bg-primary text-white">
      <q-btn flat round dense icon="cancel" @click="closeDrawer" />
      <q-toolbar-title>Settings</q-toolbar-title>
    </q-toolbar>
    <q-tabs v-model="tabs" class="bg-primary text-white" indicator-color="secondary">
      <q-tab v-if="$store.state.general.settings.showSegmentationModule" name="segmentation" icon="account_tree"  :disable="canOrganize()" />
      <q-tab name="export" icon="upgrade"  :disable="cannotexport()"/>
      <q-tab name="columns" icon="view_column"  :disable="canOrganize()"/>
      <q-tab name="saved" icon="filter_alt"  :disable="canOrganize()"/>
      <q-tab v-if="$store.state.general.settings.showClustering"  icon="mail" name="clustering" :label="$t('Clustering')" :disable="canOrganize()"/>
    </q-tabs></div>

    <segmentationDrawer v-if="tabs === 'segmentation'" :data="null"  :selected="selected" @update="emitUpdate" />
    <div v-if="tabs === 'export'" class="content">
      <div class="exportlist" v-for="ex in $store.state.general.settings.exports" :key="ex">
        <template v-if="xlsxexports[ex]">
          <div ><q-btn class="export-btn" dense :label="localized(xlsxexports[ex].label)" @click="xlsxexports[ex].generator" color="secondary" /></div>
          <div>{{localized(xlsxexports[ex].description)}}</div>
          <div v-if="xlsxexports[ex].options"></div>
          <div class="optionlist" v-if="xlsxexports[ex].options">
            <label v-for="option in xlsxexports[ex].options" :key="option.name">
              <input type="checkbox" v-model="exportoptions" :value="option.name">
              {{localized(option.label)}}
            </label>
          </div>
          <div v-if="xlsxexports[ex].prices"></div>
          <div class="optionlist" v-if="xlsxexports[ex].prices">
            <label v-for="option in xlsxexports[ex].prices" :key="option.name">
              <input type="radio" v-model="exportprices" :value="option.name">
              {{localized(option.label)}}
            </label>
          </div>
        </template>
      </div>
    </div>
    <div v-if="tabs === 'columns'" class="lg-column-chooser">
        <q-select filled square :label="$t('Select an additional column')" emit-value

          placeholder="Type in..."
          v-model="undisplayedSelect"
          :options="defs"
          use-input
          @input="addColumn"
          @filter="filterfn"
        />
      <p>{{$t('Currently displayed columns')}}</p>
      <draggable v-model="data.data.columns" @change="changeOrder">
        <q-chip v-for="(elem, index) in data.data.columns" :key="index"
              class="chip"
              removable
              color="primary"
              text-color="white"
              @remove="dropColumn(elem)"
            >{{getAttrByName(elem)}}</q-chip>
      </draggable>
      <span class="warning">{{warningMsg}}</span>
    </div>
    <div v-if="tabs === 'saved'" class="newfilter">
      <table>
        <thead>
          <tr><th colspan="3">{{$t('Existing filters')}}</th></tr>
        </thead>
        <tbody>
          <tr v-for="f in filters" :key="f.id"><td>{{localized(f.label)}}</td>
          <td><q-btn dense size="sm" color="secondary" icon="upload" @click="loadfilter(f.filter)" /></td>
          <td><q-btn dense size="sm" color="negative" :disable="!(f.email || canFilterGlobal())" icon="delete" @click="del(f.id)" /></td></tr>
        </tbody>
        <tfoot>
          <tr><th colspan="3">{{$t('Add a new filter')}}</th></tr>
          <tr><td colspan="3">
            <label><input :disable="!canFilterGlobal()" type="checkbox" v-model="global"> {{$t('Global filter')}}</label>
          </td></tr>
          <tr><td colspan="3">
            <label><input type="checkbox" v-model="newfilter.shortcut"> {{$t('With shortcut')}}</label>
          </td></tr>
          <tr><td colspan="3">
            <label><input type="checkbox" v-model="newfilter.dashboard"> {{$t('With dashboard')}}</label>
          </td></tr>
          <tr><td colspan="2">
            <localizedstring :label="$t('Label')" name="label" editable="yes" :def="{name: 'label'}" :data="{ v: newfilter.label }"
              :rules="rules" @change="dochange" />
            </td>
            <td><q-btn dense color="secondary" icon="add" @click="savefilter"/></td>
          </tr>
        </tfoot>
      </table>
    </div>
   </div>
</template>

<script>
import draggable from 'vuedraggable';
import { can } from '@cabinetmariel/scopes';
import { localization } from '../../../lib/mixins';
import localizedstring from '../../../components/attributesDisplay/localizedstring.vue';
import segmentationDrawer from './segmentationDrawer.vue';

export default {
  mixins: [localization],
  props: ['data', 'selected'],
  components: {
    localizedstring,
    draggable,
    segmentationDrawer,
  },
  data() {
    return {
      exportoptions: [],
      exportprices: 'price',
      newfilter: {
        shortcut: false,
        dashboard: false,
        label: { 'en-US': 'New filter', 'fr-FR': 'Nouveau filtre' },
      },
      global: false,
      filter: '',
      filters: [],
      undisplayedSelect: null,
      drawer: this.$store.state.catalog.drawer,
      tabs: this.$store.state.general.settings.showSegmentationModule ? 'segmentation' : 'export',
      warningMsg: null,
      locale: this.$store.state.auth.locale,
      rules(fullmodel) {
        return [
          (val) => Object.entries(fullmodel).filter(([k, v]) => !!v).every(([k, v]) => v.length > 3)
            || `Label cannot be shorter than 3 characters, ${val}`,
          (val) => (Object.entries(fullmodel).filter(([k, v]) => !!v).length >= 1)
            || 'At least a label in one language is required',
        ];
      },
    };
  },
  computed: {
    xlsxexports() {
      return {
        std: {
          label: { 'fr-FR': 'Générique', 'en-US': 'Generic' },
          description: { 'en-US': 'Exports the whole family parts (no pictures)' },
          generator: () => this.exportData('std'),
        },
        m3: {
          label: { 'fr-FR': 'M3', 'en-US': 'M3' },
          description: { 'en-US': 'Generates an M3 compliant Excel file' },
          generator: () => this.exportData('m3'),
          options: [
            { name: 'alltags', label: { 'en-US': 'Include all pricelists', 'fr-FR': 'Inclure les prix dérivés' } },
            { name: 'vdiscount', label: { 'en-US': 'Include volume discounts', 'fr-FR': 'Inclure les prix paliers' } },
          ],
          prices: [
            { name: 'price', label: { 'en-US': 'Use validated price', 'fr-FR': 'Utiliser le prix validé' } },
            { name: 'proposedprice', label: { 'en-US': 'Use proposed price', 'fr-FR': 'Utiliser le prix proposé' } },
          ],
        },
      };
    },
    catalog() {
      return this.$store.state.catalog.data;
    },
    defs() {
      const { defs } = this.$store.state.pricing.defs;
      return Object.entries(defs)
        .filter(([, attr]) => !this.data.data.columns.includes(attr.name))
        .filter(([, attr]) => (this.filter === '' || (this.localized(attr.label).toLowerCase().indexOf(this.filter) > -1)))
        .map(([name, attr]) => ({
          label: `${attr.type} --- ${this.localized(attr.label)}`,
          value: attr.name,
          id: name,
        }));
    },
  },
  methods: {
    emitUpdate(segmentation) {
      console.log('emit update SettingsDrawer', segmentation);
      this.$emit('update', segmentation);
    },
    cannotexport() {
      return !can('products.export', this.$store.state.auth.user.scopes);
    },
    dochange(v) {
      this.newfilter.label = v.label;
    },
    loadfilter(f) {
      /* console.log('entering load filter');
      localStorage.selectedColumns = f.columns;
      this.$store.commit('catalog/setFilters', f.filters || []);
      console.log('commit is done'); */
      this.$emit('load-filter', { filter: f });
    },
    del(id) {
      this.$axios.delete(`/filters/${id}`).then(() => {
        this.filters.splice(this.filters.findIndex((f) => (f.id === id)), 1);
        this.$q.notify({ type: 'positive', message: 'Filter sucessfully deleted', icon: 'done' });
      }).catch((error) => {
        console.error(error);
        this.$q.notify({ type: 'negative', message: 'Filter cannot be deleted', icon: 'warning' });
      });
    },
    savefilter() {
      const filter = {
        ...this.newfilter,
        filter: {
          columns: JSON.parse(localStorage.filter_columns),
          filters: this.$store.state.catalog.filters,
        },
        email: this.global ? undefined : this.$store.state.auth.user.email,
      };
      this.$axios.post('/filters', filter).then((response) => {
        this.filters.push(response.data.data);
        this.$q.notify({ type: 'positive', message: 'Filter sucessfully saved', icon: 'done' });
      })
        .catch((error) => {
          this.$q.notify({ type: 'negative', message: 'Filter cannot be saved', icon: 'warning' });
        });
    },
    filterfn(val, update) {
      update(() => {
        this.filter = val.toLowerCase();
      });
    },
    getAttrByName(name) {
      if (this.$store.state.pricing.defs.defs2[name]) {
        return `${this.localized(this.$store.state.pricing.defs.defs2[name].label)}`;
      }
      console.error('getAttrByName fail', name);
      return name;
    },
    exportData(type) {
      const data = type === 'm3' ? { priceattribute: this.exportprices, exportoptions: this.exportoptions.join(',') } : {};
      data.exporttype = type;
      this.$emit('export-data', data);
    },
    dropColumn(val) {
      if (this.data.data.columns.length > 1) {
        this.$emit('drop-column', val);
      } else {
        this.warningMsg = 'minimum one column';
      }
    },
    changeOrder() {
      this.$emit('reorder-columns', this.data.data.columns);
    },
    addColumn(val) {
      this.warningMsg = '';
      this.undisplayedSelect = null;
      this.$emit('add-column', val);
    },
    closeDrawer() {
      this.data.type = undefined;
    },
    canOrganize() {
      return !can('products.organize', this.$store.state.auth.user.scopes);
    },
    canFilterGlobal() {
      return can('filters.global', this.$store.state.auth.user.scopes);
    },
  },
  mounted() {
    this.$axios.get('/filters').then((response) => {
      this.filters = response.data.data;
    });
  },
};
</script>

<style lang="stylus" scoped>
.lg-column-chooser {
  padding-top: 1rem;
}
.content {
  display: flex;
  flex-direction: column;
  padding: 1em;
  .exportlist {
    display grid
    grid-template-columns: 100px 1fr
    .export-btn {
      min-width 80px
    }
    .optionlist {
      display: flex;
      flex-direction: column;
    }
  }
}

.warning {
  color: red;
  display: flex;
  justify-content: center;
  padding: 1em;
}

.drawerMenu {
  z-index: 1700;

  .q-btn {
    display: block;
  }
}

.q-item, .q-field {
  padding: 0 20px;
}

.chip {
  width: 100%;
}

.newfilter {
  padding 1rem
  table {
    width: 100%
    border-collapse: collapse;
    th {
      text-align: left
    }
    thead {
      border-bottom: 1px solid gray;
    }
    tbody {
      td {
        padding .5rem 0
      }
    }
    tfoot {
      th {
        padding-top 1rem;
        border-bottom: 1px solid gray;
      }
    }
  }
}
</style>
