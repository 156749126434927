<template>
  <div>
    <div class="content">
      <p class="selected-text1" v-if="selectionMessage1">
        {{ selectionMessage1 }}
      </p>

      <div class="create-segmentation-container">
        <q-input v-model="newSegmentation" class="create-segmentation-input" filled square :label="$t('Create a new segmentation')" />
        <q-btn class="create-segmentation-button" :color="'secondary'" @click="openDialog('create-segmentation')">
          Create
          <q-icon name="add" class="add-icon" />
        </q-btn>
      </div>

      <div class="create-segmentation-container">
        <q-select
          v-model="selectedSegmentation"
          class="create-segmentation-input"
          filled
          square
          :label="$t('Select a segmentation')"
          emit-value
          map-options
          :options="mappedSegmentationsList"
          option-value="id"
          option-label="label"
        />
        <q-btn class="create-segmentation-button" :color="'secondary'" @click="openDialog('delete-segmentation')">
          Delete
          <q-icon name="delete" />
        </q-btn>
      </div>

      <div class="ctrl-container">
        <q-btn class="segmentation-button" :color="'secondary'" @click="openDialog('apply-segmentation')">
          Apply current segmentation
        </q-btn>
      </div>
    </div>

    <p class="selected-text2" v-if="selectedSegmentation !== null">
        {{ selectionMessage2 }}
      </p>
    <div class="content">
      <p class="drawerTitle">
        Families and sub-families
        <q-icon name="add" class="add-icon" @click="openDialog('create-family')" />
      </p>
      <div class="itemContainer" v-if="selectedSegmentation !== null">
        <q-btn class="item-type1" :color="'secondary'" @click="openDialog('select-family')">Select family</q-btn>
        <div v-for="family in this.$store.state.catalog.history.families" :key="family.id" class="item-type1" @click="openDialog('apply-family', family)">
          <p>{{ family.label }}</p>
          <q-tooltip>
            {{family.label}}
          </q-tooltip>
          <q-icon name="close" class="close-icon" @click.stop="removeFamilie(family.id)" />
          <img :src="family.img" class="inline-picture" @error="handleImageError" />
        </div>
      </div>
    </div>

    <div class="content">
      <p class="drawerTitle">
        Added values
        <q-icon name="add" class="add-icon" @click="openDialog('create-value')" />
      </p>
      <div class="itemContainer" v-if="selectedSegmentation !== null">
        <q-btn class="item-type2" :color="'secondary'" @click="openDialog('select-value')" style="padding:0; font-size: 0.7em;">Select value</q-btn>
        <div v-for="value in this.$store.state.catalog.history.values" :key="value.id" class="item-type2" @click="openDialog('apply-value', value)">
          <p>{{ localized(value.label['en-US']) }}</p>
          <q-tooltip>
            {{localized(value.label['en-US'])}}
          </q-tooltip>
          <q-icon name="close" class="close-icon" @click.stop="removeValue(value.id)" />
        </div>
      </div>
    </div>

    <div class="content" style="border:none;">
      <p class="drawerTitle">
        Material
        <q-icon name="add" class="add-icon" @click="openDialog('create-material')" />
      </p>
      <div class="itemContainer" v-if="selectedSegmentation !== null">
        <q-btn class="item-type2" :color="'secondary'" @click="openDialog('select-material')" style="padding:0; font-size: 0.7em;">Select material</q-btn>
        <div v-for="material in this.$store.state.catalog.history.material" :key="material.id" class="item-type2" @click="openDialog('apply-material', material)">
          <p>{{ localized(material.label['en-US']) }}</p>
          <q-tooltip>
            {{localized(material.label['en-US'])}}
          </q-tooltip>
          <q-icon name="close" class="close-icon" @click.stop="removeMaterial(material.id)" />
        </div>
      </div>
    </div>

    <CreateFamilyDialog
      :model="showCreateFamilyDialog"
      @update:model="showCreateFamilyDialog = $event"
      @confirm="confirmAction"
    />

    <SelectFamilyDialog
      :selectedFamily="selectedFamily"
      :model="showSelectFamilyDialog"
      @update:model="showSelectFamilyDialog = $event;"
      @confirm="confirmAction"
    />

    <CreateAttrDialog
      :model="showCreateAttrDialog"
      :selected="selected"
      :set="createAttrSet"
      @update:model="showCreateAttrDialog = $event"
      @update:records="updateRecords"
      @confirm="confirmAction"
    />

    <SelectValuesDialog
      :model="showSelectValuesDialog"
      :selected="selected"
      :attributes="attributes"
      @update:model="showSelectValuesDialog = $event"
      @update:records="updateRecords"
      @confirm="confirmAction"
    />

    <SelectMaterialDialog
      :model="showSelectMaterialDialog"
      :selected="selected"
      :attributes="attributes"
      @update:model="showSelectMaterialDialog = $event"
      @update:records="updateRecords"
      @confirm="confirmAction"
    />

    <ConfirmDialog
      :model="showConfirmDialog"
      :text="confirmDialogText"
      @update:model="showConfirmDialog = $event"
      @update:records="updateRecords"
      @confirm="confirmAction"
    />

  </div>
</template>

<script>
import { remove } from 'lodash';
import localization from '../../../lib/mixins'; //eslint-disable-line
import CreateFamilyDialog from './dialog/CreateFamilyDialog.vue';
import SelectFamilyDialog from './dialog/SelectFamilyDialog.vue';
import CreateAttrDialog from './dialog/CreateAttrDialog.vue';
import SelectValuesDialog from './dialog/SelectValuesDialog.vue';
import SelectMaterialDialog from './dialog/SelectMaterialDialog.vue';
import ConfirmDialog from './dialog/ConfirmDialog.vue';
import putils from '../../../lib/pictures';

function haspicture(p) {
  return !!p?.pictures?.v?.[0];
}

export default {
  mixins: [localization],
  props: ['data', 'selected'],
  components: {
    CreateFamilyDialog,
    SelectFamilyDialog,
    SelectValuesDialog,
    CreateAttrDialog,
    SelectMaterialDialog,
    ConfirmDialog,
  },
  data() {
    return {
      locale: this.$store.state.auth.locale,

      showCreateFamilyDialog: false,
      showSelectFamilyDialog: false,
      showCreateAttrDialog: false,
      showSelectValuesDialog: false,
      showSelectMaterialDialog: false,
      showConfirmDialog: false,

      createAttrSet: null,
      createAttrType: '',
      selectedFamily: null,
      selectedValue: null,
      selectedMaterial: null,
      confirmDialogText: '',

      actionType: '',
      filters: [],
      currentFilterId: null,
      newSegmentation: '',
      segmentationsList: [],
      selectedSegmentation: null,
    };
  },
  computed: {
    defs() {
      const { defs } = this.$store.state.pricing.defs;
      return Object.entries(defs)
        .filter(([, attr]) => !this.data.data.columns.includes(attr.name))
        // .filter(([, attr]) => this.filter === '' || this.localized(attr.label).toLowerCase().includes(this.filter))
        .map(([name, attr]) => ({
          label: `${attr.type} --- ${this.localized(attr.label)}`,
          value: attr.name,
          id: name,
        }));
    },
    sets() {
      const { sets } = this.$store.state.pricing.sets;
      return Object.keys(sets)
        .map((key) => sets[key])
        .filter((set) => set.name === 'materials' || set.name === 'features');
    },
    attrsBySet() {
      return this.attributes.reduce((acc, attribute) => {
        if (!acc[attribute.set]) {
          acc[attribute.set] = [];
        }
        acc[attribute.set].push(attribute);
        return acc;
      }, {});
    },
    getMaterial() {
      if (!this.attrsBySet) return [];
      const material = this.sets.find((s) => s.name === 'materials');
      return this.attrsBySet[material.id] || [];
    },
    getFeature() {
      const feature = this.sets.find((s) => s.name === 'features');
      return this.attrsBySet[feature.id] || [];
    },
    attributes() {
      return Object.values(this.$store.state.pricing.defs.defs);
    },
    familiesHistory() {
      return this.$store.state.catalog.history?.families ?? [];
    },
    valuesHistory() {
      return this.$store.state.catalog.history?.values ?? [];
    },
    materialHistory() {
      return this.$store.state.catalog.history?.material ?? [];
    },
    mappedSegmentationsList() {
      return this.segmentationsList.map((segmentation) => ({
        id: segmentation.id,
        label: segmentation.name,
      }));
    },
    selectedSegmentationId() {
      return this.selectedSegmentation ? this.selectedSegmentation.id : null;
    },
    selectionMessage1() {
      if (this.selectedSegmentation === null) {
        return 'Please select a segmentation';
      }
      return null;
    },
    selectionMessage2() {
      return this.selected.length ? `${this.selected.length} Parts selected` : 'No parts selected';
    },
  },
  watch: {
    selectedSegmentation() {
      if (!this.selectedSegmentation) return;
      const selectedSegmentationObj = this.segmentationsList.find((s) => s.id === this.selectedSegmentation);
      if (!selectedSegmentationObj) {
        console.warn(`Segmentation with id ${this.selectedSegmentation} not found in segmentationsList`);
        return;
      }
      this.updateSegmentation();
      const label = selectedSegmentationObj.name;
      this.$store.commit('header/setHeader', {
        title: `Catalog - Segmentation: ${label}`,
      });
      this.$store.state.catalog.selectedSegmentation = this.selectedSegmentation;
    },
  },
  methods: {
    mkpath(id) {
      return putils.mkpath(id, this.$store.state.auth.token);
    },

    updateSegmentation() {
      this.$store.state.catalog.selectedSegmentation = this.selectedSegmentation;

      if (!this.selectedSegmentation) return;
      const selectedSegmentationObj = this.segmentationsList.find((s) => s.id === this.selectedSegmentation);
      if (!selectedSegmentationObj) {
        console.warn(`Segmentation with id ${this.selectedSegmentation} not found in segmentationsList`);
        return;
      }

      console.log('selectedSegmentation', selectedSegmentationObj);
      if (selectedSegmentationObj.filter) {
        console.log('selectedSegmentationObj.filter', selectedSegmentationObj.filter);
        this.currentFilterId = selectedSegmentationObj.filter;

        const filter = this.filters.find((f) => f.id === selectedSegmentationObj.filter);
        if (filter) {
          console.log('filter found !', filter.filter.filters);
          this.$store.commit('catalog/setFilters', filter.filter.filters);
          this.$emit('update', selectedSegmentationObj.id);
          this.$store.commit('catalog/setFilters', filter.filter.filters);
        }
      } else {
        // reset filter
        console.log('reset filter');
        this.$store.commit('catalog/resetFilters');
        this.$emit('update', selectedSegmentationObj.id);
      }
      this.$forceUpdate();
    },
    async getFamiliesImgs(id) {
      const columns = ['pictures'].join(',');
      const response = await this.$axios.get(`/families/${id}/parts`, { params: { columns, '@limit': 50, '@offset': 0 } });
      const { data } = response.data;
      if (data && data.length) {
        console.log('response.data.data[0]', data[0]);
        return this.mkpath(data[0]?.pictures?.v[0]);
      }
      return '/default.png';
    },
    async getFamilies(id = null) {
      if (!id) {
        const response = await this.$axios.get('/families', { params: { nolimit: 'yes', sort: 'label' } });
        const tmpData = await Promise.all(response.data.data.map(async (f) => ({
          id: f.id,
          parent: f.parent ? f.parent : null,
          label: this.localized(f.label),
          name: f.name,
          leaf: f.leaf,
          img: await this.getFamiliesImgs(f.id),
        })));
        return tmpData;
      }

      const response = await this.$axios.get(`/families/${id}/siblings`, { params: { nolimit: 'yes', sort: 'label' } });
      const tmpData = await Promise.all(response.data.data.map(async (f) => ({
        id: f.id,
        parent: f.parent ? f.parent : null,
        label: this.localized(f.label),
        name: f.name,
        leaf: f.leaf,
        img: await this.getFamiliesImgs(f.id),
      })));
      return tmpData;
    },
    handleImageError(event) {
      event.target.src = '/default.jpg';
    },
    updateRecords(name) {
      console.log('updateRecords', name);
      this.updateSegmentation();
    },
    checkBeforeDialog() {
      if (this.selected.length === 0) {
        this.showNotification('negative', 'Please select at least one part', 'warning');
        return false;
      }
      return true;
    },
    openDialog(actionType, item = null) {
      console.log('openDialog', actionType, item);
      if (
        actionType !== 'create-segmentation'
        && actionType !== 'delete-segmentation'
        && actionType !== 'apply-segmentation'
        && actionType !== 'create-family'
        && actionType !== 'select-family'
        && actionType !== 'create-value'
        && actionType !== 'create-material') {
        if (!this.checkBeforeDialog()) return;
      }

      const { filters } = this.$store.state.catalog;
      const segmentationLabel = this.segmentationsList.find((s) => s.id === this.selectedSegmentation)?.name;
      const materialSet = this.sets.find((set) => set.name === 'materials');
      const valueSet = this.sets.find((set) => set.name === 'features');

      this.actionType = actionType;
      switch (actionType) {
        // Segmentation actions
        case 'create-segmentation':
          if (filters.length > 0) {
            this.confirmDialogText = `You are about to create a new segmentation "${this.newSegmentation}" with the ${filters.length} current filters, are you sure?`;
          } else {
            this.confirmDialogText = `You are about to create a new segmentation "${this.newSegmentation}" with the current filters, are you sure?`;
          }
          this.showConfirmDialog = true;
          break;
        case 'delete-segmentation':
          this.confirmDialogText = `You are about to delete the ${segmentationLabel} segmentation, are you sure?`;
          this.showConfirmDialog = true;
          break;
        case 'apply-segmentation':
          this.confirmDialogText = `You are about to merge the ${segmentationLabel} segmentation in the DEFAULT segmentation, are you sure?`;
          this.showConfirmDialog = true;
          break;
        // Family actions
        case 'create-family':
          this.showCreateFamilyDialog = true;
          break;
        case 'select-family':
          this.showSelectFamilyDialog = true;
          break;
        case 'apply-family':
          this.selectedFamily = item.id;
          this.confirmDialogText = `You are about to move the ${this.selected.length} selected parts to the family "${item.label}", are you sure?`;
          this.showConfirmDialog = true;
          break;
          // Value actions
        case 'create-value':
          this.createAttrType = 'value';
          if (materialSet) {
            this.createAttrSet = valueSet.id;
            console.log('create-value', this.createAttrSet);
            this.showCreateAttrDialog = true;
          } else {
            console.error('Material set not found');
          }
          break;
        case 'select-value':
          this.showSelectValuesDialog = true;
          break;
        case 'apply-value':
          this.selectedValue = item.id;
          this.confirmDialogText = `You are about to add the attribute "${this.localized(item.label)}" to the ${this.selected.length} selected parts, are you sure?`;
          this.showConfirmDialog = true;
          break;
          // Material actions
        case 'create-material':
          this.createAttrType = 'material';
          if (materialSet) {
            this.createAttrSet = materialSet.id;
            console.log('create-material', this.createAttrSet);
            this.showCreateAttrDialog = true;
          } else {
            console.error('Material set not found');
          }
          break;
        case 'select-material':
          this.showSelectMaterialDialog = true;
          break;
        case 'apply-material':
          this.selectedMaterial = item.id;
          this.confirmDialogText = `You are about to add the attribute "${this.localized(item.label)}" to the ${this.selected.length} selected parts, are you sure?`;
          this.showConfirmDialog = true;
          break;
        default:
          break;
      }
    },

    async addHistoryFamilies(familyId) {
      const exists = this.$store.state.catalog.history.families.some((f) => f.id === familyId);
      if (exists) return;
      const res = await this.getFamilies(familyId);
      const family = res.find((f) => f.id === familyId);
      this.$store.state.catalog.history.families.unshift(family);
      if (this.$store.state.catalog.history.families.length > 11) {
        this.$store.state.catalog.history.families.pop();
      }
      console.log('history.families', this.$store.state.catalog.history.families);
      this.$forceUpdate();
    },
    async updateFamily() {
      const res = await Promise.all(this.$store.state.catalog.history.families.map((f) => this.getFamilies(f.id)));
    },
    removeFamilie(familyId) {
      console.log('removeFamilie', familyId);
      this.$store.state.catalog.history.families = this.$store.state.catalog.history.families.filter((f) => f.id !== familyId);
      this.$forceUpdate();
    },

    addHistoryValues(params) {
      // Iterate over the keys in params
      Object.keys(params).forEach((key) => {
        // Check if the value is true
        if (params[key] === true) {
          console.log('addHistoryValues', params);
          // Find the attribute with the name matching the key
          const value = this.attributes.find((att) => att.name === key);
          console.log('value', value);
          // Check if the value is already in the history
          const exists = this.$store.state.catalog.history.values.some((v) => v.name === key);
          if (!exists && value) {
            this.$store.state.catalog.history.values.unshift(value);
            console.log('history.values', this.$store.state.catalog.history.values);
            if (this.$store.state.catalog.history.values.length > 5) {
              this.$store.state.catalog.history.values.pop();
            }
          }
        }
      });
    },
    removeValue(valueId) {
      console.log('removeValue', valueId);
      this.$store.state.catalog.history.values = this.$store.state.catalog.history.values.filter((v) => v.id !== valueId);
      this.$forceUpdate();
    },

    addHistoryMaterial(params) {
      console.log('addHistoryMaterial', params);
      // Iterate over the keys in params
      Object.keys(params).forEach((key) => {
        // Check if the value is true
        if (params[key] === true) {
          console.log('addHistoryMaterial', params);
          // Find the attribute with the name matching the key
          const material = this.attributes.find((att) => att.name === key);
          console.log('material', material);
          // Check if the material is already in the history
          const exists = this.$store.state.catalog.history.material.some((m) => m.name === key);
          if (!exists && material) {
            this.$store.state.catalog.history.material.unshift(material);
            console.log('history.material', this.$store.state.catalog.history.material);
            if (this.$store.state.catalog.history.material.length > 5) {
              this.$store.state.catalog.history.material.pop();
            }
          }
        }
      });
    },
    removeMaterial(materialId) {
      console.log('removeMaterial', materialId);
      this.$store.state.catalog.history.material = this.$store.state.catalog.history.material.filter((m) => m.id !== materialId);
      this.$forceUpdate();
    },

    confirmAction(data) {
      if (data.actionType) this.actionType = data.actionType;
      console.log('confirmAction', this.actionType, data);

      // segmentation actions
      if (this.actionType === 'delete-segmentation') {
        this.deleteSegmentation();
      } else if (this.actionType === 'create-segmentation') {
        this.createSegmentation();
      } else if (this.actionType === 'apply-segmentation') {
        this.applySegmentation();
      // Family actions
      } else if (this.actionType === 'create-family') {
        this.move(this.selected, data.selectedFamily);
        this.addHistoryFamilies(data.selectedFamily);
      } else if (this.actionType === 'select-family') {
        this.move(this.selected, data.selectedFamily);
        this.addHistoryFamilies(data.selectedFamily);
        this.updateFamily();
      } else if (this.actionType === 'apply-family') {
        this.move(this.selected, this.selectedFamily);
        this.updateFamily();
      } else if (this.actionType === 'delete-family') {
        this.deleteFamily(data.toDelete);
        // Value actions
      } else if (this.actionType === 'create-value') {
        const selectedParts = this.selected;
        this.updateSegmentation();
        if (data.attrId) {
          this.selectedMaterial = data.attrId;
          const partsIds = selectedParts.map((p) => p.id);
          const params = this.makeMaterialParams(partsIds, this.selectedMaterial);
          this.addMaterial(partsIds, params);
          this.addHistoryValues(params);
        }
        this.updateSegmentation();
        this.showCreateAttrDialog = false;
      } else if (this.actionType === 'select-value') {
        if (data.params) {
          const partsIds = this.selected.map((p) => p.id);
          this.addMaterial(partsIds, data.params);
          this.addHistoryValues(data.params);
        }
      } else if (this.actionType === 'apply-value') {
        const partsIds = this.selected.map((p) => p.id);
        const params = this.makeMaterialParams(partsIds, this.selectedValue);
        this.addMaterial(partsIds, params);
      // Material actions
      } else if (this.actionType === 'create-material') {
        const selectedParts = this.selected;
        this.updateSegmentation();
        if (data.attrId) {
          console.log('apply new material to parts');
          console.log('data', data.attrId);
          this.selectedMaterial = data.attrId;
          const partsIds = selectedParts.map((p) => p.id);
          const params = this.makeMaterialParams(partsIds, this.selectedMaterial);
          this.addMaterial(partsIds, params);
          this.addHistoryMaterial(params);
        }
        this.updateSegmentation();
        this.showCreateAttrDialog = false;
      } else if (this.actionType === 'select-material') {
        // Done inside the dialog
        if (data.params) {
          const partsIds = this.selected.map((p) => p.id);
          this.addMaterial(partsIds, data.params);
          this.addHistoryMaterial(data.params);
        }
      } else if (this.actionType === 'apply-material') {
        const partsIds = this.selected.map((p) => p.id);
        const params = this.makeMaterialParams(partsIds, this.selectedMaterial);
        this.addMaterial(partsIds, params);
      }

      this.showConfirmDialog = false;
      this.actionType = '';
    },
    async fetchSegmentations() {
      try {
        const resSegm = await this.$axios.get('/segmentations');
        this.segmentationsList = resSegm.data.data;
        console.log('fetchSegmentations segmentationsList', this.segmentationsList);

        this.$axios.get('/filters').then((resFilters) => {
          const filters = resFilters.data.data;
          this.filters = filters;
          console.log('fetchSegmentations filters:', filters);

          // Find "DEFAULT" segmentation
          const defaultSegmentation = this.segmentationsList.find((s) => s.name === 'DEFAULT');
          if (defaultSegmentation) {
            this.$store.state.catalog.defaultSegmentation = defaultSegmentation.id;
          }
        });
      } catch (error) {
        console.error(error);
      }
    },

    async deleteFamily(familyId) {
      console.log('deleteFamily', familyId);
      try {
        const response = await this.$axios.delete(`/families/${familyId}`);
        this.showNotification('positive', this.$t('Family got deleted'), 'done');
        // remove from history
        this.$store.state.catalog.history.families = this.$store.state.catalog.history.families.filter((f) => f.id !== familyId);
        this.updateSegmentation();
      } catch (error) {
        this.showNotification('negative', this.$t('Something wrong happen trying to delete the family'), 'warning');
        this.updateSegmentation();
      }
    },

    async move(parts, familyId) {
      console.log('move', parts, familyId);
      try {
        const response = await this.$axios.patch(`/segmentations/${this.selectedSegmentation}`, {
          family: familyId,
          products: parts.map((item) => item.id),
        });
        this.showNotification('positive', this.$t('Part(s) got moved'), 'done');
        this.updateSegmentation();
      } catch (error) {
        this.showNotification('negative', this.$t('Something wrong happen trying to move the part(s)'), 'warning');
        this.updateSegmentation();
      }
    },

    addValues() {
      console.log('addValues', this.selectedValue);
    },

    makeMaterialParams(ids, materialId) {
      const attributesValues = this.attributes.reduce((res, att) => {
        res[att.id] = {
          ...att,
          ...(att.type === 'number' ? { number: null, fixed: null, unit: '' } : {}),
          ...(att.type === 'boolean' ? { checked: att.id === materialId } : {}),
        };
        return res;
      }, {});

      const booleans = Object.keys(attributesValues)
        .filter((att) => attributesValues[att].checked)
        .map((filteredAttribute) => ({ [attributesValues[filteredAttribute].name]: attributesValues[filteredAttribute].checked }))
        .reduce((prevObj, currentObj) => ({ ...prevObj, ...currentObj }), { ids });

      const numbers = Object.keys(attributesValues)
        .filter((attribute) => attributesValues[attribute].number != null)
        .map((filteredAttribute) => ({
          [attributesValues[filteredAttribute].name]: {
            number: Number(attributesValues[filteredAttribute].number),
            fixed: `${attributesValues[filteredAttribute].number}`,
            unit: attributesValues[filteredAttribute].unit,
          },
        }))
        .reduce((prevObj, currentObj) => ({ ...prevObj, ...currentObj }), { ids });

      console.log('booleans', booleans);
      console.log('numbers', numbers);
      const params = {
        ...booleans,
        ...numbers,
      };

      return params;
    },
    async addMaterial(ids, params) {
      console.log('addMaterial', ids, params);

      try {
        console.log('/products params', params);
        const res = await this.$axios.patch('/products', params);
        this.updateSegmentation();
        this.$emit('confirm', res);

        this.$q.notify({
          icon: 'done',
          color: 'positive',
          message: this.$t('Attributes have been updated for {{productCount}} products', { productCount: ids.length }),
        });
      } catch (error) {
        this.$q.notify({
          icon: 'warning',
          color: 'negative',
          message: this.$t('The attribute cannot be updated'),
        });
      }
    },
    applySegmentation() {
      if (!this.selectedSegmentation) {
        this.showNotification('negative', 'Please select a segmentation to apply', 'warning');
        return;
      }

      console.log('applySegmentation', this.selectedSegmentation);
      this.$axios.post(`/segmentations/${this.selectedSegmentation}/apply`).then((response) => {
        this.showNotification('positive', 'Segmentation applied', 'done');
        // select default segmentation
        // this.selectedSegmentation = this.segmentationsList.length ? this.segmentationsList[0].id : null;
        this.updateSegmentation();
      }).catch((error) => {
        console.error(error);
        this.showNotification('negative', 'Segmentation cannot be applied', 'warning');
      });
    },
    deleteSegmentation() {
      if (!this.selectedSegmentation) {
        this.showNotification('negative', 'Please select a segmentation to delete', 'warning');
        return;
      }

      console.log('deleteSegmentation', this.selectedSegmentation);
      this.$axios.delete(`/segmentations/${this.selectedSegmentation}`).then((response) => {
        this.showNotification('positive', 'Segmentation deleted', 'done');
        this.fetchSegmentations();

        // set defaut segmentation
        this.selectedSegmentation = this.segmentationsList.length ? this.segmentationsList[0].id : null;
      }).catch((error) => {
        console.error(error);
        this.showNotification('negative', 'Segmentation cannot be deleted', 'warning');
      });
    },
    parseFilters() {
      return JSON.stringify(this.$store.state.catalog.filters.reduce((_, f) => {
        let { value } = f;
        if (!Array.isArray(value)) value = [value];
        Array.prototype.push.apply(_, value.map((v) => ({
          column: f.name,
          op: v.action,
          args: Array.isArray(v.value) ? v.value : [v.value],
        })));
        Array.prototype.push.apply(_, value.filter((v) => !!v.noempty).map((v) => ({
          column: f.name,
          op: 'not_empty',
          args: [],
        })));
        return _;
      }, []));
    },
    hasFilters() {
      return this.$store.state.catalog.filters.length > 0;
    },
    createSegmentation() {
      if (!this.newSegmentation) {
        this.showNotification('negative', 'Please enter a name for the new segmentation', 'warning');
        return;
      }

      let url = '/segmentations';
      if (this.hasFilters()) {
        const filters = this.parseFilters();
        console.log('filters', filters);
        url += `?@filters=${filters}`;
      }

      this.$axios.post(url, { name: this.newSegmentation }).then((response) => {
        this.showNotification('positive', 'Segmentation created', 'done');
        this.fetchSegmentations();
        this.selectedSegmentation = response.data.data.id;
        this.newSegmentation = '';
      }).catch((error) => {
        console.error(error);
        this.showNotification('negative', 'Segmentation cannot be created', 'warning');
      });
    },
    showNotification(type, message, icon) {
      this.$q.notify({ type, message, icon });
    },
  },

  async created() {
    this.$store.state.catalog.segmentationDrawerIsOpen = true;
    await this.fetchSegmentations();
    if (this.$store.state.catalog.selectedSegmentation) {
      this.selectedSegmentation = this.$store.state.catalog.selectedSegmentation;
    } else {
      this.selectedSegmentation = this.segmentationsList.length ? this.segmentationsList[0].id : null;
    }

    this.$store.state.catalog.history = {
      families: [],
      values: [],
      material: [],
    };
    this.$store.state.catalog.history.families = [...await this.getFamilies()];
    this.$store.state.catalog.history.values = [this.getFeature[0]];
    this.$store.state.catalog.history.material = [this.getMaterial[0]];
    this.$forceUpdate();
  },
  async beforeDestroy() {
    this.$store.state.catalog.segmentationDrawerIsOpen = false;
  },
};
</script>

<style lang="stylus" scoped>
.create-segmentation-container {
  display: flex;
  align-items: center;
  width: 100%;
  //border:1px solid red;
  margin-bottom: 1em;
  padding: 0 1em;
}

.create-segmentation-input{
  flex: 1;
}

.create-segmentation-button {
  margin-left: 1em;
  height: 50px;
  width:50px;
  font-size: 0.8em;
}

.ctrl-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  //margin-bottom: 1em;
  padding: 0 1em;
}

.segmentation-button{
  width: 100%;
  margin-bottom: 1em;
  font-size: 0.9em;
}

.selected-text1
.selected-text2{
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom:0.5em;
  color: #ff8700;
  width: 100%;
  text-align: center;
}
.selected-text2{
  margin-top: 0.5em;
}

.content {
  display: flex;
  flex-direction: column;
  padding-top:  1em;
  border-bottom: 1px solid #ccc;
}

.drawerTitle {
  font-size: 1.2em;
  font-weight: bold;
  color: #444;
  width: 100%;
  text-align: center;
  margin-top: -0.25em;
  margin-bottom: 0.5em;
}

.add-icon{
  cursor: pointer;
  font-size:1.4em;
}
.add-icon:hover {
  transform: scale(1.2);
}

.itemContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  max-height: 400px !important;
  overflow-y: scroll;
  padding: 0 1em;
}

.item-type1
.item-type2{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  padding: 10px;
  height: 100px;
  width:75px;
  cursor: pointer;
  font-size: 0.9em;
  margin-left: 8px;
  &:hover {
    background-color: #f0f0f0;
  }

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin:0;
    width: 100%;
  }

  img {
    height: 50px;
    border: 1px solid #ccc;
    object-fit: contain;
    border-radius: 5px;
  }
}

.item-type1{
  flex-direction: column;
  position : relative;
  .close-icon {
    position: absolute;
    top: 3px;
    right:2px;
  }
}

.item-type2 {
  flex-direction: row;
  justify-content: center
  height: 40px;
  width: 115px;

  p {
    text-align: center;
    font-size: 0.7em;
    font-weight: bold;
  }
}

.close-icon {
  cursor: pointer;
  font-size: 1.1em;
  margin-left: 8px;
  &:hover {
    color: red;
  }
}
</style>
