<template>
  <div v-if="$store.state.pricing.rule && $store.state.pricing.rule.definition && $store.state.pricing.defs.defs2
  && $store.state.pricing.rule.definition.common && nrstore().parts && lib" class="drawer-container">
    <q-list no-border dense>
      <q-expansion-item :label="$t('Common')"  header-class="accordeon-params">
        <div class="lg-common-settings row">
          <div class="col-12">
            <q-select v-if="$store.state.pricing.rule.method === 'kit'" :disable="readonly" filled square dense :label="$t('Kit price based on')"
              :options="kitbasedonoptions"
              v-model="kitbasedon" :emit-value="true" />
            <q-select :disable="readonly" filled square dense :label="$t('Proportionality function')"
              :options="['linear','asymptotic']" v-model="proxyfoo"/>
            <q-select :disable="readonly" v-if="$store.state.general.settings.classification &&  $store.state.pricing.defs.defs2.classification" filled square dense :label="$t('Classification Override')"
              :options="overrides" v-model="override" />
            <q-select :disable="readonly" :options="['EUR','CHF','CNY','GBP','USD','AUD']" filled square dense
              v-model="currency" :label="$t('Currency for pivot and amount')" />
            <q-select :disable="readonly" v-model="currencytag" emit-value map-options :options="ratelist" :label="$t('Currency Rate Type')" filled square dense/>
            <q-input :disable="readonly" filled square dense type="number" :step="0.01" :label="$t('Minimal margin')" suffix="%"
              :value="$store.state.pricing.rule.definition.common.margin"
              debounce="500"
              @input="val => updateRuleCommon('margin', val)"/>
            <q-input :disable="readonly" filled square dense type="number" :step="0.01" :label="$t('Average Discount')" suffix="%"
              debounce="500" :value="$store.state.pricing.rule.definition.common.averagediscount"
              @input="val => updateRuleCommon('averagediscount', val)"/>
            <q-select :disable="readonly" v-model="marginbasis" :options="marginbasisoptions" :label="$t('Margin basis')"
              filled square dense emit-value map-options/>
            <q-select :disable="readonly" v-model="minimalpricebasis" :options="marginbasisoptions" :label="$t('Minimal price basis')"
              filled square dense emit-value map-options/>
            <q-checkbox class="common-check" :disable="readonly" v-model="fullcostingthreshold" filled square dense :label="$t('Apply small flows add value after minimal price')"/>
            <q-select :disable="readonly" filled square dense :label="$t('PMP set')" emit-value map-options v-model="pmp" :options="pmps"></q-select>

            <q-select :disable="readonly || !$store.state.general.settings.pricesetselector" filled square dense :label="$t('Parents price set')" emit-value map-options v-model="parentpriceset" :options="parentpricesets"></q-select>
            <q-select :disable="readonly" filled square dense :label="$t('Pricing method')" emit-value map-options v-model="pricingmethod" :options="pricingmethods"></q-select>

            <q-input :disable="readonly" filled square dense type="number" :step="0.01" :label="$t('Maximal upgrade')" suffix="%"
              :value="$store.state.pricing.rule.definition.common.upgrade"
              debounce="500"
              @input="val => updateRuleCommon('upgrade', val)"/>
            <q-select :disable="readonly" filled square dense :label="$t('Feature impact methodology')" emit-value map-options
              v-model="featureimpact" :options="featureimpacts"></q-select>
            <q-checkbox class="common-check" :disable="readonly" v-model="missingmaterial" filled square dense :label="$t('Auto add missing material')"/>

            <q-select :disable="readonly" filled square dense :label="$t('Competition level')" emit-value map-options
              v-model="competitionlevel" :options="competitionslevels"></q-select>
          </div>
        </div>
      </q-expansion-item>
      <q-expansion-item v-if="$store.state.pricing.rule.definition.pivot && $store.state.pricing.rule.definition.pivot.length && ['vp', 'cpt'].includes($store.state.pricing.rule.method)" :default-opened="true" header-class="accordeon-params">
        <template v-slot:header>
          <q-item-section>
            <select class="pivotselect" v-model="pivottype" @click="(e) => e.stopPropagation()" @input="(e) => e.stopPropagation()">
              <option v-for="method in pivottypes" :value="method.value" :key="method.value">{{method.label}}</option>
            </select>
          </q-item-section>
          <q-item-section side>
            <q-btn color="primary" dense small icon="adjust" @click="adjustRulePivot" :title="$t('Use average values')"/>
          </q-item-section>
        </template>
        <!-- <q-item v-for="ref in $store.state.pricing.rule.definition.pivot" :key="ref.name"> -->
        <div class="pivot-actions" v-if="pivottype === 'one'">
          <q-radio dense small v-model="autopivotmode" class="" val="increase"/>
          <q-input v-model="targetincrease" class="autopivot" dense small filled square type="number" suffix="%" :label="$t('Number of increases')"/>
          <q-radio dense small v-model="autopivotmode" class="" val="margin"/>
          <q-input v-model="targetmargin" class="autopivot" dense small filled square type="number" suffix="%" :label="$t('Target margin...')">
          </q-input>
          <q-btn color="primary" dense small icon-right="play_arrow" icon="motion_photos_auto"  @click="autoPivot" :title="$t('Auto pivot')">
          </q-btn>
        </div>
        <div v-if="pivottype === 'multi'" class="multipivot-block">
          <div class="lg-other-settings row">
            <div class="col-12">
              <q-select debounce="500" :label="$t('Attribute to use')" map-options emit-value dense small filled square
                :options="currentattributes" @filter="filterAttributes" :value="$store.state.pricing.rule.definition.multipivot.attribute"
                @input="(value) => updateMultipivot({ value, field: 'attribute' })"></q-select>
            </div>
          </div>
          <div class="lg-other-settings row">
            <div class="col-12">
              <q-input debounce="500" :label="$t('Date of validity')" filled square dense small type="date"
                :value="$store.state.pricing.rule.definition.multipivot.date.toISOString().substr(0, 10)"
                @input="(value) => updateMultipivot({ value, field: 'date' })"></q-input>
            </div>
          </div>
          <div class="lg-other-settings row">
            <div class="col-12">
              <q-select debounce="500" :label="$t('Dimension to use')" map-options emit-value dense small filled square
                :options="dimensions" :value="$store.state.pricing.rule.definition.multipivot.dimension"
                @input="(value) => updateMultipivot({ value, field: 'dimension' })"></q-select>
            </div>
          </div>
          <div class="lg-other-settings row">
            <div class="col-6">
              <q-input type="number" dense small filled square :label="$t('# of parts in lower set :')"
                :value="$store.state.pricing.rule.definition.multipivot.boundaries[0].size" debounce="500"
                @input="(value) => updateMultipivot({ value, field: 'boundaries', index: 0, subfield: 'size' })" />
            </div>
            <div class="col-6">
              <q-input type="number" dense small filled square :label="$t('Coef. for lower set :')"
                :value="$store.state.pricing.rule.definition.multipivot.boundaries[0].coef" debounce="500"
                @input="(value) => updateMultipivot({ value, field: 'boundaries', index: 0, subfield: 'coef' })" />
            </div>
          </div>
          <div class="lg-other-settings row">
            <div class="col-6">
              <q-input type="number" dense small filled square :label="$t('# of parts in upper set :')"
                :value="$store.state.pricing.rule.definition.multipivot.boundaries[1].size" debounce="500"
                @input="(value) => updateMultipivot({ value, field: 'boundaries', index: 1, subfield: 'size' })" />
            </div>
            <div class="col-6">
              <q-input type="number" dense small filled square :label="$t('Coef. for upper set :')"
              :value="$store.state.pricing.rule.definition.multipivot.boundaries[1].coef" debounce="500"
              @input="(value) => updateMultipivot({ value, field: 'boundaries', index: 1, subfield: 'coef' })" />
            </div>
          </div>
          <div class="lg-other-settings row">
            <div class="col-12">
              <q-input type="number" dense small filled square :label="$t('Level')"
                :value="$store.state.pricing.rule.definition.multipivot.level" debounce="500"
                @input="(value) => updateMultipivot({ value, field: 'level' })" />
            </div>
          </div>
        </div>
        <div v-else-if="pivottype === 'multi2'" class="multipivot-block">
          <div class="lg-other-settings settingheader" ><div class="title">{{$t('Price reference :')}}</div></div>
          <div class="lg-other-settings row">
            <div class="col-12">
              <q-select debounce="500" :label="$t('Attribute to use')" map-options emit-value dense small filled square
                :options="currentattributes" @filter="filterAttributes" :value="$store.state.pricing.rule.definition.multipivot2.attrs.price"
                @input="(value) => updateMultipivot({ value, field: 'attrs', subfield: 'price' })"></q-select>
            </div>
          </div>
          <div class="lg-other-settings row">
            <div class="col-12">
              <q-input debounce="500" :label="$t('Date of validity')" filled square dense small type="date"
                :value="$store.state.pricing.rule.definition.multipivot2.pricedate.toISOString().substr(0, 10)"
                @input="(value) => updateMultipivot({ value, field: 'pricedate' })"></q-input>
            </div>
          </div>
          <div class="lg-other-settings settingheader" ><div class="title">{{$t('Meaningful dimension :')}}</div></div>

          <div class="lg-other-settings row">
            <div class="col-12">
              <q-select debounce="500" :label="$t('Dimension to use')" map-options emit-value dense small filled square
                :options="dimensions" :value="$store.state.pricing.rule.definition.multipivot2.attrs.dimension"
                @input="(value) => updateMultipivot({ value, field: 'attrs', subfield: 'dimension' })"></q-select>
            </div>
          </div>

          <div class="lg-other-settings settingheader" ><div class="title">{{$t('Sales :')}}</div></div>
          <div class="lg-other-settings row">
            <div class="col-12">
              <q-select debounce="500" :label="$t('Attribute to use')" map-options emit-value dense small filled square
                :options="currentattributes" @filter="filterAttributes" :value="$store.state.pricing.rule.definition.multipivot2.attrs.sales"
                @input="(value) => updateMultipivot({ value, field: 'attrs', subfield: 'sales' })"></q-select>
            </div>
          </div>
          <div class="lg-other-settings row">
            <div class="col-12">
              <q-input debounce="500" :label="$t('Large flow threshold')" filled square dense small type="number"
                :value="$store.state.pricing.rule.definition.multipivot2.lfthreshold"
                @input="(value) => updateMultipivot({ value, field: 'lfthreshold' })"></q-input>
            </div>
          </div>

          <div class="lg-other-settings settingheader" ><div class="title">{{$t('Power curve :')}}</div>
            <q-btn class="recompute" color="primary" dense small icon="refresh" @click.native="recomputeMultipivot2Parameters" :title="$t('Use average values')"/>
          </div>
         <div class="lg-other-settings row">
            <div class="col-12">
              <q-input debounce="500" :label="$t('a')" filled square dense small type="number" :disable="true"
                :value="round($store.state.pricing.rule.definition.multipivot2.powercurve.family.a, decimals)"
                @input="(value) => updateMultipivot({ value, field: 'powercurve', subfield: 'a' })"></q-input>
            </div>
          </div>
          <div class="lg-other-settings row">
            <div class="col-12">
              <q-input debounce="500" :label="$t('b')" filled square dense small type="number" :disable="true"
                :value="round($store.state.pricing.rule.definition.multipivot2.powercurve.family.b, decimals)"
                @input="(value) => updateMultipivot({ value, field: 'powercurve', subfield: 'b' })"></q-input>
            </div>
          </div>
          <div class="lg-other-settings row">
            <q-badge color="warning">TIP</q-badge> {{$t('Reference price = a * (dimension) ^ b.')}}
          </div>
          <div class="lg-other-settings settingheader" ><div class="title">{{$t('Levels and rates :')}}</div></div>
          <div class="lg-other-settings row lg-force-right">
            <div class="col-6">
              <q-input :disable="readonly" debounce="500" :label="$t('Manual LF Level')" filled square dense small type="number"
                :value="$store.state.pricing.rule.definition.multipivot2.levels.manuallf"
                @input="(value) => updateMultipivot({ value, field: 'levels', subfield: 'manuallf' })">
              </q-input>
            </div>
            <div class="col-6">
              <q-input :disable="true" debounce="500" :label="$t('System LF Level')" filled square dense small type="number"
                :value="round($store.state.pricing.rule.definition.multipivot2.levels.computedlf, decimals)"
                @input="(value) => updateMultipivot({ value, field: 'levels', subfield: 'lf' })">
                  <template v-slot:after>
                    <q-btn :disable="readonly" dense flat round icon="o_cancel" @click="updateMultipivot({ field: 'levels', subfield: 'manuallf', value: 0 })" />
                  </template>
              </q-input>
            </div>
          </div>
          <div class="lg-other-settings row lg-force-right">
            <div class="col-6">
              <q-input :disable="readonly" debounce="500" :label="$t('Expected ratio')" filled square dense small type="number"
                :value="$store.state.pricing.rule.definition.multipivot2.levels.manualexpected"
                @input="(value) => updateMultipivot({ value, field: 'levels', subfield: 'manualexpected' })"></q-input>
            </div>
            <div class="col-6">
              <q-input :disable="true" debounce="500" :label="$t('Expected ratio')" filled square dense small type="number"
                :value="round($store.state.pricing.rule.definition.multipivot2.levels.computedexpected, decimals)"
                @input="(value) => updateMultipivot({ value, field: 'levels', subfield: 'expected' })">
                  <template v-slot:after>
                    <q-btn :disable="readonly" dense flat round icon="o_cancel" @click="updateMultipivot({ field: 'levels', subfield: 'manualexpected', value: 0 })" />
                  </template>
              </q-input>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-for="ref in $store.state.pricing.rule.definition.pivot" :key="ref.name" class="lg-other-settings row">
            <div class="col-6">
                <q-input :disable="readonly" filled square dense v-if="$store.state.pricing.defs.defs2[ref.name].type==='number'"
                  type="number" :step="0.01"
                  :label="$store.state.pricing.defs.defs2[ref.name].label[locale]"
                  :value="Number(ref.v.fixed)||0" :suffix="ref.v.unit"
                  debounce="500"
                  @input="val => updateRuleGeneric('pivot', ref.name, val)">
                </q-input>
                <q-input :disable="readonly" filled square dense v-if="['amount', 'pricing'].includes($store.state.pricing.defs.defs2[ref.name].type)"
                  type="number" :step="0.01"
                  :label="$store.state.pricing.defs.defs2[ref.name].label[locale]"
                  :value="Number(ref.v.fixed)||0" :suffix="ref.v.unit"
                  debounce="500"
                  @input="val => updateRuleGeneric('pivot', ref.name, val)">
                </q-input>
            </div>
            <div class="col-6" v-if="$store.state.pricing.averagereferences[ref.name]">
              <q-input  filled square dense :disable="true" :label="$t('(Average)')" :value="$store.state.pricing.averagereferences[ref.name].value" :suffix="ref.v.unit">
                <q-btn slot="after" flat round dense :disable="(ref.name === 'price') || readonly" :tabindex="-1" icon="delete"/>
              </q-input>
            </div>
          </div>
        </div>
      </q-expansion-item>
      <q-expansion-item  header-class="accordeon-params" :default-opened="true" v-for="set in usedsets" :key="set.id" :label="localized(set.label)">
        <div class="row lg-other-settings" v-for="attr in activeattr(rule, set)" :key="attr.name">
          <div class="col-6">
              <q-input :disable="readonly" filled square dense :label="localized($store.state.pricing.defs.defs2[attr.name].label)"
                :value="Number(attr.v.fixed)" type="number" :suffix="attr.v.currency"
                v-if="attr.type === 'amount'"
                debounce="500"
                @input="val => updateRuleGeneric('attributes', attr.name, val)"
              >
              </q-input>
              <q-input :disable="readonly"  filled square dense :label="localized($store.state.pricing.defs.defs2[attr.name].label)"
                :value="attr.v" type="number"
                v-if="attr.type === 'rate'"
                debounce="500"
                @input="val => updateRuleGeneric('attributes', attr.name, val)"
              >
              </q-input>
          </div>
          <div class="col-6">
            <q-input filled square dense :disable="true" :label="$t('System Value')" :value="getSystemValue(attr, $store.state.pricing.rule).fixed" :suffix="getSystemValue(attr, $store.state.pricing.rule).unit">
              <q-btn :disable="readonly" slot="after" :tabindex="-1" icon="delete" flat round dense @click="removeAttr(attr.name)" />
            </q-input>
          </div>
        </div>
      </q-expansion-item>
      <q-expansion-item header-class="accordeon-params" :default-opened="true" :label="$t('Currency rates')" style="padding-left:10px; padding-right:10px;" v-if="pricingmethod==='change'">
        {{$t('All currency rates are computed automatically, there is nothing to configure within the rule.')}}
      </q-expansion-item>

      <q-expansion-item header-class="text-weight-bold" :default-opened="true" :label="$t('Cost plus')" style="padding-left:10px; padding-right:10px;" v-if="pricingmethod==='cost+'">
        <div class="lg-other-settings row">
          <div class="col-12">
            <q-checkbox :disable="readonly" v-model="cumulativeweighting" filled square dense :label="$t('Cumulative Weighting')"/>
          </div>
          <div class="col-12">
            <p class="notice">{{$t('If checked please make sure to add the Weighting attribute. This rate will be applied to the computed rate.')}}</p>
          </div>
        </div>
        <q-btn color="primary" :disable="readonly" icon="edit" @click="openModale()" style="width:290px; height:40px;" >
        {{$t('Edit')}}'
        <q-spinner color="secondary" size="1em" v-if="showSpin" style="margin-left:10px" />
        </q-btn>
        <modale v-if="showEdit" :show="showEdit" :label="'Cost plus edit'" :value="costPlusTree" @closemodale="closeModale" @ready="readyModale" @input="costPlusHasChange" style="margin-top: 10px;"></modale>
      </q-expansion-item>

    </q-list>
    <q-btn v-if="!readonly" color="secondary"  dense fab round icon="add" class="fixed ruleparams-fab"
      @click="go('addselect')"/>
  </div>
</template>
<script>
import pricingUtilities from '@cabinetmariel/pricing-utilities';
import pricingMethods from '@cabinetmariel/pricing-methods';
import localization from '../../lib/mixins'; // eslint-disable-line
import { store } from '../../lib/nrstore';
import { getSystemValue } from '../../lib/partutils';
import modale from './costPlus/CostPlusEdit';

const { pricinglib, utils } = pricingUtilities;
const { methods: methodsList } = pricingMethods;
const { isFamilyBased } = utils;
const { mklib } = pricinglib;

export default {
  mixins: [localization],
  components: {
    modale,
  },
  data() {
    return {
      manual: {
        expected: null,
        lf: null,
      },
      decimals: 4,
      targetmargin: 80,
      targetincrease: 60,
      autopivotmode: 'margin',
      lib: null,
      locale: this.$store.state.auth.locale,
      showEdit: false,
      showSpin: false,
      pricesets: ['DEFAULT'],
      destroychart: true,
      currentattributes: [],
      // Cost plus as a parameter
      costPlusTree: [],
    };
  },
  computed: {
    competitionslevels() {
      return this.$store.state?.general?.settings?.competition?.levels?.map((level) => ({
        value: level.name,
        label: this.localized(level.label),
      }));
    },
    cumulativeweighting: {
      get() {
        return this.$store.state.pricing.rule.definition.cumulativeweighting || false;
      },
      set(v) {
        this.$store.commit('pricing/setCumulativeWeighting', v);
        this.$root.$emit('updatechart', true);
      },
    },
    competitionlevel: {
      get() {
        const def = this.$store.state?.general?.settings?.competition?.levels?.[0]?.name;
        return this.$store.state.pricing.rule.definition.common.competitionlevel || def;
      },
      set(v) {
        this.$store.commit('pricing/setCompetitionLevel', { value: v, settings: this.$store.state.general.settings });
        this.$root.$emit('updatechart', true);
      },
    },
    featureimpacts() {
      return [
        { value: 'multiplicative', label: this.$t('Multiplicative') },
        { value: 'additive', label: this.$t('Additive') },
      ];
    },
    featureimpact: {
      get() {
        return this.$store.state.pricing.rule.definition.common.featureimpact || 'multiplicative';
      },
      set(v) {
        this.$store.commit('pricing/setFeatureImpact', { value: v, settings: this.$store.state.general.settings });
        this.$root.$emit('updatechart', true);
      },
    },
    ratelist() {
      return this.$store.state.general.settings.rates.map((r) => ({ value: r.name, label: this.localized(r.label) }));
    },
    attributes() {
      if (this.$store.state?.pricing?.defs?.defs2) {
        return Object.values(this.$store.state.pricing.defs.defs2).map((attr) => ({ value: attr.name, label: this.localized(attr.label) }));
      }
      return [];
    },
    dimensions() {
      const dimensionset = Object.values(this.$store.state.pricing.sets.sets).find((set) => (set.name === 'dimensions'));
      return Object.values(this.$store.state.pricing.defs.defs2)
        .filter((attr) => (attr.set === dimensionset.id))
        .map((attr) => ({ value: attr.name, label: this.localized(attr.label) }))
        .sort((a, b) => (a.label > b.label ? 1 : -1));
    },
    pivottypes() {
      const types = [
        { value: 'one', label: this.$t('Mono Pivot') },
      ];
      if (this.$store.state.pricing.partsLength > this.$store.state.general.settings.minpartsformultipivot) {
        types.push({ value: 'multi', label: this.$t('Multi Pivot') });
        types.push({ value: 'multi2', label: this.$t('Multi Pivot 2') });
      }
      return types;
    },
    kitbasedonoptions() {
      return [
        { value: 'price', label: this.localized(this.$store.state.pricing.defs.defs2.price.label) }, // FIXME : use attr label
        { value: 'proposedprice', label: this.localized(this.$store.state.pricing.defs.defs2.proposedprice.label) },
      ];
    },
    marginbasisoptions() {
      return [
        { value: 'selectedpmp', label: this.localized(this.$store.state.pricing.defs.defs2.selectedpmp.label) }, // FIXME : use attr label
        { value: 'fullcosting', label: this.localized(this.$store.state.pricing.defs.defs2.fullcosting.label) },
      ];
    },
    missingmaterial: {
      get() {
        return !!(this.$store.state.pricing.rule.definition.common.missingmaterial);
      },
      set(v) {
        this.$store.commit('pricing/setMissingMaterial', { value: v, settings: this.$store.state.general.settings });
        this.$root.$emit('updatechart', true);
      },
    },
    fullcostingthreshold: {
      get() {
        return (this.$store.state.pricing.rule.definition.common.fullcosting || {}).mode !== 'threshold';
      },
      set(v) {
        this.$store.commit('pricing/setFullcostingThreshold', v);
        this.$root.$emit('updatechart', true);
      },
    },
    marginbasis: {
      get() {
        return (this.$store.state.pricing.rule.definition.common.margins || {}).basis
          || this.$store.state.general.settings.margins.basis
          || 'selectedpmp';
      },
      set(v) {
        this.$store.commit('pricing/setMarginBasis', v);
        this.$root.$emit('updatechart', true);
      },
    },
    minimalpricebasis: {
      get() {
        return (this.$store.state.pricing.rule.definition.common.minimalprice || {}).basis
          || this.$store.state.general.settings.minimalprice.basis
          || 'selectedpmp';
      },
      set(v) {
        this.$store.commit('pricing/setMinimalpriceBasis', v);
        this.$root.$emit('updatechart', true);
      },
    },
    currencytag: {
      get() {
        return this.$store.state.pricing.rule.definition.currencytag || this.$store.state.general.settings.rates[0].name;
      },
      set(v) {
        this.$store.commit('pricing/setCurrencyTag', v);
        this.$root.$emit('updatechart', true);
      },
    },
    kitbasedon: {
      get() {
        return this.$store.state.pricing.rule.definition.kitbasedon || this.kitbasedonoptions[0].value;
      },
      set(v) {
        this.$store.commit('pricing/setKitBasedOn', v);
      },
    },
    proxyfoo: {
      get() {
        return this.$store.state.pricing.rule.definition.proxyfoo || 'linear';
      },
      set(v) {
        this.$store.commit('pricing/setProxyFoo', v);
      },
    },
    readonly() {
      return !!((this.$store.state.pricing.single && isFamilyBased(this.$store.state.pricing.rule)) || this.$store.state.pricing.rule.link);
    },
    overrides() {
      if (!(this.$store.state.pricing.defs.defs2.classification
        && this.$store.state.pricing.defs.defs2.classification.settings
        && this.$store.state.pricing.defs.defs2.classification.settings.values)) return [''];
      return ['', ...this.$store.state.pricing.defs.defs2.classification.settings.values.map((o) => o.name)];
    },
    override: {
      get() {
        return this.$store.state.pricing.rule.definition.override;
      },
      set(v) {
        this.$store.commit('pricing/setMethodOverride', v);
      },
    },
    currency: {
      get() {
        return this.$store.state.pricing.rule.currency;
      },
      set(v) {
        this.$store.commit('pricing/setRuleCurrency', { currency: v, locale: this.$store.state.auth.locale, settings: this.$store.state.general.settings });
        this.$root.$emit('updatechart', true);
      },
    },
    pivottype: {
      get() {
        return this.$store.state.pricing.rule.definition.pivottype;
      },
      set(v) {
        this.$store.commit('pricing/setPivotType', { pivottype: v, settings: this.$store.state.general.settings });
      },
    },
    pmp: {
      get() {
        return this.$store.state.pricing.rule.definition.common.pmp;
      },
      set(v) {
        this.updateRuleCommon('pmp', v);
      },
    },
    parentpriceset: {
      get() {
        return this.$store.state.pricing.rule.parent;
      },
      set(v) {
        this.$store.commit('pricing/updateParentPriceSet', v);
        this.$root.$emit('updatechart', true);
      },
    },
    parentpricesets() {
      return this.pricesets.filter((el) => el !== this.$store.state.pricing.rule.tag);
    },
    pricingmethod: {
      get() {
        return this.$store.state.pricing.rule.method;
      },
      set(v) {
        this.$store.commit('pricing/updatePricingMethod', { method: v, settings: this.$store.state.general.settings });
      },
    },
    pricingmethods() {
      return Object.keys(methodsList);
    },
    usedsets() {
      if (!this.$store.state.pricing.sets.sets) return [];
      if (!this.rule.definition.attributes) return [];
      return Object.values(this.$store.state.pricing.sets.sets)
        .filter((set) => this.rule.definition.attributes.findIndex((a) => (a.set === set.name)) >= 0)
        .filter((set) => ((this.pivottype === 'one') || (set.name !== 'dimensions')));
    },
    rule() {
      return this.$store.state.pricing.rule;
    },
    pmps() {
      const tmp = store.parts
        .reduce((_, p) => [...new Set([..._, ...Object.keys(p[this.$store.state.pricing.rule.definition.common.pmpattr] || []).filter((e) => (e !== 'set'))])], []);
      return tmp.sort().map((p) => ({ label: p, value: p }));
    },
  },
  mounted() {
    this.currentattributes = this.attributes;
    this.loadPricesets();
    this.lib = mklib(this.$store.state.pricing, this.$store.state.general.settings, () => {});

    // Load CostPlus from store
    this.loadCostPlus();
  },
  methods: {
    round(val, decimals = 2) {
      const factor = 10 ** decimals;
      return Math.round((val + Number.EPSILON) * factor) / factor;
    },
    loadCostPlus() {
      // get tree from store
      let tmpCostPlus = [];
      if (this.$store.state?.pricing?.rule?.definition?.costplus) {
        tmpCostPlus = this.$store.state.pricing.rule.definition.costplus;
      } else {
        tmpCostPlus = [{
          condition: {
            attribute: 'producthierarchy',
            op: 'sw',
            args: [''],
          },
          children: [
            {
              decision: '1',
            },
          ],
        }];
      }

      this.costPlusTree = tmpCostPlus;
    },
    costPlusHasChange(newCostPlus) {
      console.log('costPlusHasChange', newCostPlus);

      this.$store.commit('pricing/setCostPlus', newCostPlus);
      this.$q.notify({ type: 'positive', message: 'Your modifications have been saved', icon: 'done' });
      this.closeModale();
    },
    filterAttributes(val, update) {
      if (val === '') return update(() => { this.currentattributes = this.attributes; });
      return update(() => {
        this.currentattributes = this.attributes.filter((a) => (a.label.toLowerCase().indexOf(val.toLowerCase()) >= 0));
      });
    },
    go(name, params = {}) {
      if (name === this.$route.name && (!params.pid || params.pid === this.$route.params.pid)) return;
      const { href } = this.$router.resolve({ name, params });
      this.$router.push(this.$store.state.pricing.single ? href.replace('/pricing', '/one') : { name, params });
    },
    isFamilyB() {
      return isFamilyBased(this.$store.state.pricing.rule);
    },
    openModale() {
      this.showSpin = true;
      setTimeout(() => {
        this.showEdit = true;
      }, 500);
    },
    readyModale() {
      this.showSpin = false;
    },
    closeModale() {
      this.showEdit = false;
      // this.$forceUpdate();
      this.destroychart = false;
      this.$nextTick(function destroychart() {
        this.destroychart = true;
      });
    },
    chart() {
      const drawMe = store.parts.map((p) => this.$store.state.pricing.rule.definition.costPlusFunc(p));
      // drawMe.map((v) => (v === undefined ? 1 : v));
      const chartdatatmp = {
        chart: {
          zoomType: 'x',
          inverted: false,
          reflow: true,
          width: 320,
          height: 250,
        },
        title: {
          text: '',
        },
        subTitle: {
          text: '',
        },
        xAxis: {
          title: null,
          categories: store.parts.map((p) => p.name.v),
        },
        yAxis: {
          labels: {
            align: 'right',
            x: -3,
          },
          title: {
            text: 'Coef',
          },
          lineWidth: 2,
          resize: {
            enabled: true,
          },
        },
        series: [
          {
            type: 'line',
            name: 'Coef',
            color: '#90E310',
            data: drawMe.map((p) => p),
          },
        ],

      };

      return chartdatatmp;
    },
    loadPricesets() {
      this.$axios.get('attributes/price/tags', {}).then((res) => {
        this.pricesets = res.data.data;
      });
    },
    nrstore() {
      return store;
    },
    getSystemValue({ name, type }, rule) {
      return getSystemValue({
        attribute: { name, type },
        rule,
        defs: this.$store.state.pricing.defs.defs2,
        settings: this.$store.state.general.settings,
        lookupRate: this.lib.lookupRate,
      });
    },
    usedseriesets(attr) {
      return store.parts
        .reduce((_, p) => [...new Set([..._, ...Object.keys(p[attr] || []).filter((e) => (e !== 'set'))])], [])
        .map((p) => ({ label: p, value: p }));
    },

    activeattr(rule, set) {
      return rule.definition.attributes.filter((item) => item.set === set.name);
    },
    numberOf(group) {
      const len = Object.keys(this.$store.state.pricing.rule[group]).length;
      return `${len} properties`;
    },
    updateMultipivot(payload) {
      const version = this.pivottype === 'multi' ? '' : '2';
      this.$store.commit('pricing/setProgress', 'Refreshing prices...');
      this.$store.commit(`pricing/updateMultipivot${version}`, { payload, settings: this.$store.state.general.settings });
      this.$root.$emit('updatechart', true);
      this.$store.commit('pricing/setProgress', null);
    },
    adjustRulePivot() {
      this.$store.commit('pricing/setProgress', 'Refreshing prices...');
      this.$store.commit('pricing/adjustRulePivot');
      this.$root.$emit('updatechart', true);
      this.$store.commit('pricing/setProgress', null);
    },
    recomputeMultipivot2Parameters() {
      this.$store.commit('pricing/setProgress', 'Refreshing prices...');
      this.$store.commit('pricing/recomputeMultipivot2Parameters', this.$store.state.general.settings);
      this.$root.$emit('updatechart', true);
      this.$store.commit('pricing/setProgress', null);
    },
    autoPivot() {
      this.$store.commit('pricing/setProgress', 'Refreshing prices...');
      this.$store.commit('pricing/autoPivot', {
        settings: this.$store.state.general.settings,
        target: this.autopivotmode === 'margin' ? this.targetmargin : this.targetincrease,
        autopivotmode: this.autopivotmode,
      });
      this.$root.$emit('updatechart', true);
      this.$store.commit('pricing/setProgress', null);
    },
    updateRuleGeneric(rulepart, name, v) {
      this.$store.commit('pricing/setProgress', 'Refreshing prices...');
      this.$store.commit('pricing/updateRulePivot', { rulepart, name, v });
      this.$root.$emit('updatechart', true);
      this.$store.commit('pricing/setProgress', null);
    },
    updateRuleCommon(name, value) {
      this.$store.commit('pricing/setProgress', 'Refreshing prices...');
      this.$store.commit('pricing/updateRuleCommon', {
        name,
        value,
        settings: this.$store.state.general.settings,
        locale: this.$store.state.auth.locale,
      });
      this.$root.$emit('updatechart', true);
      this.$store.commit('pricing/setProgress', null);
    },
    removeAttr(name) {
      this.$store.commit('pricing/setProgress', 'Refreshing prices...');
      this.$store.commit('pricing/removeAttribute', {
        name,
        settings: this.$store.state.general.settings,
        locale: this.$store.state.auth.locale,
      });
      this.$root.$emit('updatechart', true);
      this.$store.commit('pricing/setProgress', null);
    },
  },
};
</script>
<style lang="stylus" scoped>
.q-field {
  padding: 1px;
}
.notice {
  font-size: 0.8rem;
  color: #666;
  padding-left: 1.6rem;
  text-align: justify;
}
</style>
<style lang="stylus">
.settingheader {
  border-top: 1px solid black;
  position: relative;
  margin: 20px 0;
    margin-left: 0.5rem;
  .title {
    position: absolute;
    top: -10px;
    display: inline-block;
    background: white;
    padding: 0 10px;
    margin: 0 10px;
  }
  .recompute {
    position: absolute;
    right: 10px;
    top: -1rem;
  }
}
.pivotselect {
  outline:0;
  border:0;
}
.accordeon-params {
  font-weight: bold
  border-bottom: 1px solid black;
}
.pivottype, .multipivot-block {
  padding-right: 40px;
}
div.drawer-container {
  overflow auto
  height calc(100vh - 255px)
  .rule-name {
    padding 1rem
  }
  .ruleparams-fab {
    right 1rem
    bottom 1rem
  }

  .lg-common-settings {
    padding-left: .5rem;
    padding-right: 2.5rem;
  }
  .lg-other-settings {
    padding-left: 0.5rem;
    &.lg-force-right {
      margin-right: -40px;
    }
  }
  .common-check {
    background-color: #f2f2f2;
    padding-left: .5rem;
    margin: 1px
  }
  .pivot-actions {
    display: flex;
    gap: 0.5rem;
    padding-left: 0.5rem;
    padding-bottom: 0.1rem;
    .autopivot {
      flex: 1;
    }
    padding: 1rem;
  }
}
</style>
